import axios from "@/axios.js"

export default {
  addItem({commit}, item) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v1/cms/bill_config", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDataListItems({commit}) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/bill_config")
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDataListMenuItems({commit}) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/bill_config?CB05=1&limit=5")
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getDataListMenuItems({commit}) {
    return new Promise((resolve, reject) => {
      axios.get("api/v1/cms/bill_config/recent?sortBy=CB05:asc")
        .then((response) => {
          commit('SET_ITEMS', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  fetchDataItem({commit}) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/cms/bill_config/config")
        .then((response) => {
          commit('SET_ITEM', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateData() {
    return new Promise((resolve, reject) => {
      axios.put("/api/v1/cms/bill_config/config")
        .then((response) => {
          // commit('SET_ITEM', response.data)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.put(`/api/v1/cms/bill_config/${item.id}`, item)
        .then((response) => {
          commit('UPDATE_ITEM', item)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  removeItem({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/api/v1/cms/bill_config/${itemId}`)
        .then((response) => {
          commit('REMOVE_ITEM', itemId)
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}

<template>
  <div id="data-list-list-view" class="vx-col w-full mb-base data-list-container">
    <vs-table ref="table" pagination :max-items="20" search :data="datas">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <vs-button @click="$router.push('/user/create')" :disabled="billIdNow.meter > 0"
                     class="ml-2">เพิ่มข้อมูล
          </vs-button>
          <span class="mx-3">เลือกพื้นที่/กลุ่ม</span>
          <v-select @input="setArea" :options="areas" :value="areas" :clearable="false" v-model="selectedArea"
                    style="width: 200px"/>
        </div>
      </div>
      <template slot="thead">
        <vs-th>ลำดับที่</vs-th>
        <vs-th sort-key="M01">รหัสสมาชิก</vs-th>
        <vs-th sort-key="M04">ชื่อสกุล</vs-th>
        <vs-th sort-key="M05">บ้านเลขที่</vs-th>
        <vs-th sort-key="M02">พื้นที่/กลุ่ม</vs-th>
        <vs-th sort-key="M23">สถานะผู้ใช้น้ำ</vs-th>
        <vs-th sort-key="M10">ขนาดมิเตอร์</vs-th>
        <vs-th>คำสั่ง</vs-th>
      </template>
      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="index" v-for="(tr, index) in data">
          <vs-td>
            <p class="product-name font-medium">{{ index + 1 }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium">{{ tr.M01 || '-' }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium">{{ tr.M04 || '-' }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium">{{ tr.M05 || '-' }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium">{{ tr.M02 || '-' }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium">{{ tr.M23 || '-' }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium">{{ tr.M10 || '-' }}</p>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click="$router.push('/user/edit/'+tr._id)"/>
          </vs-td>

        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>

</template>

<script>
  import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss';
  import vSelect from 'vue-select';
  import moduleMember from "@/store/member/moduleMember.js";
  import moduleArea from "@/store/area/moduleArea.js";
  import moduleBillConfig from "@/store/billconfig/moduleBillConfig.js";

  export default {
    components: {
      vSelect
    },
    data() {
      return {
        selectedArea: {label: '', value: ''},
        user: [],
        bill: {},
        billIdNow: {
          billId: '',
          meter: '',
          user: '',
          name: ''
        }
      }
    },
    computed: {
      areas() {
        let options = [];
        this.$store.state.area.items.forEach(function (item) {
          options.push({
            'label': item.A02,
            'value': item.A01
          });
        });
        options.unshift({
          'label': 'กรุณาเลือกพื้นที่/กลุ่ม',
          'value': ''
        }, {
          'label': 'ทั้งหมด',
          'value': 'all'
        });
        return options;
      },
      datas() {
        return this.$store.state.member.items;
      }
    },
    methods: {
      async init() {
        const bill = await this.$store.state.billConfigs.item;
        this.bill = await this.$store.state.billConfigs.item;
        this.selectedArea.label = await this.$store.state.area.items[0].A02;
        this.selectedArea.value = await this.$store.state.area.items[0].A01;
        this.billIdNow = {
          billId: bill.current.CB01,
          meter: bill.bookListCount,
          user: bill.current.CB06,
          name: bill.current.CB04
        };
        this.getMemberByArea(this.selectedArea.value);
      },
      async getMember() {
        this.$vs.loading()
        await this.$store.dispatch("member/fetchDataListItems");
        // this.user = this.$store.state.member.items;
        this.$vs.loading.close();
      },
      async getMemberByArea(item) {
        this.$vs.loading()
        await this.$store.dispatch("member/fetchDataListItemsByArea", {M02: item});
        // this.user = this.$store.state.member.items;
        this.$vs.loading.close();
      },
      setArea(item) {
        // if (item.value === '') {
        //   this.user = [];
        // } else
        if (item.value === 'all') {
          this.getMember();
        } else {
          this.getMemberByArea(item.value);
        }
      }
    },
    async created() {
      this.$vs.loading()
      if (!moduleArea.isRegistered) {
        this.$store.registerModule('area', moduleArea);
        moduleArea.isRegistered = true
      }
      if (!moduleMember.isRegistered) {
        this.$store.registerModule('member', moduleMember);
        moduleMember.isRegistered = true;
      }
      await this.$store.dispatch("area/fetchDataListItems");
      if (!moduleBillConfig.isRegistered) {
        this.$store.registerModule('billConfigs', moduleBillConfig);
        moduleBillConfig.isRegistered = true;
      }
      await this.$store.dispatch("billConfigs/fetchDataItem")
      this.init();
    }
  }

</script>

<style lang="scss">
  #data-list-list-view {
    .vs-con-table {

      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            & + i {
              left: 1rem;
            }

            &:focus + i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table--not-data {
        display: none;
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

          td {
            padding: 20px;

            &:first-child {
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }

            &:last-child {
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }

          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
</style>
